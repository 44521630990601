<template>
  <vx-card class="user-profile-page"
           :class="{'main-box top-zero-radius md:w-1/2': !courtId, 'no-box-shadow': courtId > 0}"
           v-if="court && Object.keys(court).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :actionsList="actionsList"
                      @showCourtPrice="setCourtPricePromptStatus = true"
                      @setUserMaxDebt="setUserMaxDebtPromptStatus = true"/>


    <!-- show court price prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="setCourtPricePromptStatus"
      @close="setCourtPricePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('court.update')" @click="handleClick('editCourtPrice')">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('courts.price.list.dynamicTitle', {name: getUserProfile.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
            <div class="mr-2" @click="printPage">
              <custom-icon icon="PRINT"/>
            </div>
            <div class="ml-2" @click="setCourtPricePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <courts-price-list :court-id="courtId" :court-name="getUserProfile.name" />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </vx-card>
</template>

<script>
  import {getCourt} from '@/http/requests/courts'
  import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
  import {checkUserPermissions} from '@/assets/js/functions'
  import CustomIcon from '../../../../components/customIcon/customIcon'
  import {getAvatarUrl} from "../../../../assets/js/functions"
  import CourtsPriceList from "../price/list/courtsPriceList";

  export default {
    name: 'courtProfile',
    components: {CourtsPriceList, CustomIcon, ProfileMainBox},
    props: {
      courtId: 0
    },
    metaInfo () {
      return {
        title: this.user ? this.$t('courts.profile.dynamicTitle', {name: this.court.general.name.value}) : this.$t('courts.profile.title')
      }
    },
    data () {
      return {
        setCourtPricePromptStatus: false,
        setUserMaxDebtPromptStatus: false,
        showUserAddressesPromptStatus: false,
        showUserTransactionsPromptStatus: false,
        actions: {
          toolbar: [
            {
              id: {name: 'editUser', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'icon-edit-2',
              iconPack: 'feather',
              color: 'warning'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'users'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        },
        court: null,
        actionsList: [
          {
            event: 'showCourtPrice',
            // params: {id: this.termId || this.$route.params.id},
            header: '',
            i18n: 'courts.profile.actions.price',
            icon: 'icon-dollar-sign',
            iconPack: 'feather',
            permission: 'court.update'
          },
        ]
      }
    },
    created () {
      //check permissions
      if (!checkUserPermissions('user.update')) {
        delete this.actions.toolbar
      }

      if (!this.courtId) {
        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', this.actions)
          this.$store.dispatch('updateContentNavbarStyle', 'sticky')
          this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
        }, 50)
      }

      this.getCourt()
    },
    computed: {
      getUserProfile () {
        const court = {
          avatar: this.court.avatar ? getAvatarUrl(this.court.avatar) : require('@/assets/images/unnamed.png'),
          name: `${this.court.general.name.value}`,
          general: this.court.general,
          asiderAvatar: [
            {
              content: `${this.court.general.name.value}`
            },
            {
              content: this.court.general.type,
              preContent: this.$t('courts.profile.asiderAvatar.type')
            }
          ],
          details: [],
          importantData: []
        }

        return court
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCourt () {

        getCourt(this.courtId || this.$route.params.id).then(res => {
          const court = res.data.data
          let courtType = ''
          if (court.type === 1) {
            courtType = this.$t('courts.types.hard')
          } else if (court.type === 2) {
            courtType = this.$t('courts.types.ground')
          } else if (court.type === 3) {
            courtType = this.$t('courts.types.grass')
          }

          this.court = {
            general: {
              name: {
                value: court.name,
                isInvalid: ''
              },
              type: courtType
            },
            avatar: court.avatar || ''
          }

          if (!this.court.access) {
            this.actionsList.splice(2, 1)
          }

          if (!this.courtId) {
            this.$store.dispatch('setPageTitle', this.$t('courts.profile.dynamicTitle', {name: this.court.general.name.value}))
          }
        })
      },
      handleClick (id) {
        document.getElementById(id).click()
      },
      printPage () {
        window.print()
      }
    }
  }
</script>

<style lang="scss">
  .user-profile-page {

    .vx-card__body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  @media print {
    .con-vs-dialog.very-big-prompt {
      .vs-dialog {
        height: 99% !important;
        width: 99% !important;
        max-width: 99% !important;
      }
    }
  }

  @page {
    margin: 0.5mm;
  }
</style>
