<template>
  <div class="terms-price-list">

    <draggable-dynamic-table ref="courtsPriceListTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="showCourt"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editCourtPricePromptStatus"
      @close="editCourtPricePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editCourtPriceBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('courts.price.edit.dynamicTitle', {name: courtName}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editCourtPricePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <edit-court-price :court-id="courtId" :in-modal="true" @insert="reloadCourtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="editCourtPrice" v-show="false" @click="editCourtPricePromptStatus = true"/>
  </div>
</template>

<script>
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import {getTimePrices} from '@/http/requests/timePrices'
  import CustomIcon from '../../../../../components/customIcon/customIcon'
  import EditCourtPrice from "../edit/editCourtsPrice";
  import {addComma} from "../../../../../assets/js/functions";

  export default {
    name: 'CourtsPriceList',
    metaInfo() {
      return {
        title: this.$t('courts.price.list.title')
      }
    },
    components: {
      EditCourtPrice,
      CustomIcon,
      DraggableDynamicTable
    },
    props: {
      courtId: 0,
      courtName: ''
    },
    data() {
      return {
        requestSent: false,
        editCourtPricePromptStatus: false,
        loadingTimer: 0,
        options: {
          id: 'courtsPriceListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'day_7',
            i18n: 'courts.price.table.header.friday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_6',
            i18n: 'courts.price.table.header.thursday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_5',
            i18n: 'courts.price.table.header.wednesday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_4',
            i18n: 'courts.price.table.header.tuesday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_3',
            i18n: 'courts.price.table.header.monday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_2',
            i18n: 'courts.price.table.header.sunday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_1',
            i18n: 'courts.price.table.header.saturday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'termName',
            i18n: 'courts.price.table.header.termName',
            width: '150px',
            minWidth: 120,
            align: 'center',
          }
        ],
        data: [],
        filters: [`court=${this.courtId}`],
        sorts: ['order[0]=term_id,asc'],
        selectedUser: {},
        page: 1,
        endedList: false,
        actions: [
          {
            toolbar: [
              {
                id: 'insertNewCourt',
                icon: 'icon-plus',
                iconPack: 'feather',
                color: 'success',
                permission: 'user.create'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              {
                id: 'downloadTable',
                icon: 'icon-download',
                iconPack: 'feather'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getCourtPrices()
    },
    methods: {
      getCourtPrices() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.courtsPriceListTable && this.data.length === 0) {
                this.$refs.courtsPriceListTable.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.courtsPriceListTable && this.data.length > 0) {
                this.$refs.courtsPriceListTable.loadMoreStatus = 'Loading'
              }

              getTimePrices(this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                let data = {}
                res.data.data.forEach((court) => {
                  if (court.term) {
                    if (!data[`term_${court.term.id}`]) {
                      data[`term_${court.term.id}`] = {}
                    }
                    data[`term_${court.term.id}`]['termName'] = court.term.name || ''
                    data[`term_${court.term.id}`][`day_${court.week_day}`] = addComma(court.price)
                  }
                })
                const dataArray = Object.values(data)
                setTimeout(() => {
                  dataArray.forEach((item) => {
                    this.data.push(item)
                  })
                }, 100)

                // this.page = res.data.pagination.current_page + 1
                // if (res.data.pagination.current_page === 1) {
                // const row_index = this.columnsLabel.map((e) => {
                //   return e.field
                // }).indexOf('row')
                // this.columnsLabel[row_index].footer.value = res.data.pagination.total
                // }

                if (this.$refs.courtsPriceListTable) this.$refs.courtsPriceListTable.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch((error) => {
                  // console.log(error)
                  if (this.$refs.courtsPriceListTable) this.$refs.courtsPriceListTable.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      showCourt(court) {
        // this.selectedUser = court
        // this.showTermPromptStatus = true
      },
      setFilters(filters) {
        const filters_list = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'accessGroup':
              if (filters[key].search !== '') filters_list.push(`role=${filters[key].search}`)
              break

            case 'gender':
              if (filters[key].search.value > 0) filters_list.push(`gender=${filters[key].search.value}`)
              break

            case 'phoneNumber':
              if (filters[key].search !== '') filters_list.push(`phone_number=${filters[key].search}`)
              break

            case 'company':
              if (filters[key].search !== '') filters_list.push(`company=${filters[key].search}`)
              break

            case 'name':
              if (filters[key].search !== '') filters_list.push(`full_name=${filters[key].search}`)
              break

            case 'registryDate':
              if (filters[key].search !== '') filters_list.push(`created_at=${filters[key].search}`)
              break
          }
        })
        filters_list.push(`court=${this.courtId}`)

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getCourtPrices()
      },
      setSort(columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {

            case 'row':
              sorts_list.push(`order[0]=id,${columns[key] ? 'desc' : 'asc'}`)
              break

            /*case "accessGroup":
              sorts_list.push("order[0]=role," + (columns[key] ? 'desc' : 'asc'))
              break;*/

            case 'maxDebt':
              sorts_list.push(`order[0]=max_debt,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'balance':
              sorts_list.push(`order[0]=virtual_balance,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'phoneNumber':
              sorts_list.push(`order[0]=phone_number,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'company':
              sorts_list.push(`order[0]=company,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'name':
              sorts_list.push(`order[0]=name,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'registryDate':
              sorts_list.push(`order[0]=created_at,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'group':
              sorts_list.push(`order[0]=group,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'gender':
              sorts_list.push(`order[0]=gender,${columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (!sorts_list.length) sorts_list.push('order[0]=term_id,asc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getCourtPrices()
      },
      reloadCourtsList() {
        this.data = []
        this.page = 1
        this.endedList = false
        this.getCourtPrices()
        this.editCourtPricePromptStatus = false
      },
      consoleLog() {
        // console.log(event)
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .terms-price-list {
    height: 100%;
  }
</style>
